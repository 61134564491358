.pagewrapper {
  padding: 1rem 0;
}

.booking {
  background: #252b48;
  border-radius: 10px;
  padding: 5px 10px;
}
.booking_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}
.booking_header h4 {
  font-size: 16px;
}
.booking_header button {
  width: 120px !important;
  padding: 5px;
  color: white;
  border-radius: 3px;
  border: 1px solid transparent;
  font-size: 12px;
  transition: all 0.2s ease-in-out;
  text-transform: none;
}

.allUsers_header_actions {
  display: flex;
  gap: 1rem;
}

.allUsers_header_actions button:first-child {
  background-color: #4fc8e9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
}

.allUsers_header_actions button:last-child {
  background-color: #ff2552;
}

.booking_header button:first-child:hover {
  background-color: transparent;
  border: 1px solid #4fc8e9;
}

.booking_header button:last-child:hover {
  background-color: transparent;
  border: 1px solid #ff2552;
}

.userBasicDetail {
  background-color: #252b48;
  border-radius: 10px;
  padding: 1rem 1rem;
}

.userBasicDetail .updateCustomer {
  display: flex;
  justify-content: end;
  align-items: center;
}
.userBasicDetail .updateCustomer button {
  color: white;
  background-color: transparent;
  border: 1px solid;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
  transition: all 0.2s ease-in-out;
}

.userBasicDetail .basicDetailRow {
  display: flex;
  align-items: center;
  gap: 2rem;
  justify-content: flex-start;
}
.userBasicDetail .basicDetailRow .basicDetail {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 45%;
  font-size: 16px;
  margin: 10px 0;
  /* border: 1px solid;
  padding: 10px;
  border-radius: 5px; */
}
.userBasicDetail .basicDetailRow .basicDetail span {
  color: #4fc8e9;
}
.userBasicDetail .basicDetailRow .basicDetail label {
  /* width: 160px; */
  text-align: left;
}
.userBasicDetail .basicDetailRow .basicDetail input {
  background-color: transparent;
  border: none;
  color: #4fc8e9;
  outline: #4fc8e9;
  padding: 5px 5px;
  border-radius: 5px;
  min-width: 220px;
}
.userBasicDetail .basicDetailRow .editing input {
  /* background-color: transparent; */
  border: 1px solid #8cd2e6;
  /* color: #4fc8e9; */
  /* outline: #4fc8e9; */
}
.userBasicDetail .basicDetailRow .editing .field input {
  border: none;
}
.userBasicDetail .basicDetailRow .editing .field .MuiFormControl-root {
  width: 220px;
}
.userBasicDetail .edit {
  display: flex;
  justify-content: end;
  align-items: center;
}
.userBasicDetail .edit svg {
  width: 30px;
  cursor: pointer;
}

.userAppDetails {
  padding: 1rem 0;
}
.userAppDetails .userAppDetailsNav {
  padding: 10px 0 7px;
}
.userAppDetails .userAppDetailsNav span {
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.userAppDetails .userAppDetailsNav .selected {
  border-bottom: 1px solid;
  background-color: #252b48;
}
.userAppDetailsBody {
  background-color: #252b48;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 1rem 1rem;
}
.tripsCards {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.tripsCard {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid;
  padding-bottom: 1rem;
}
.tripsCard .tripsCardSection {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
}

.tripsCard .tripsCardSection .tripCarImg img {
  border-radius: 10px;
  max-width: 100%;
}
.tripsCard .tripsCardSection .tripDetails {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  /* padding: 10px 0; */
}
.tripsCard .tripBookingDetail {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: column;
  text-align: right;
}
.tripsCard .tripBookingDetail .tripTag {
  padding: 5px;
  border: 1px solid;
  border-radius: 5px;
  width: 150px;
  text-align: center;
  color: red;
}
.tripsCard .tripBookingDetail .completed {
  color: green;
}

.notes {
  /* height: 150px; */
  overflow-y: auto;
}

.notesTab .note {
  border: 1px solid;
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 10px;
  border-radius: 10px;
  gap: 1rem;
  margin-bottom: 10px;
}

.notesTab .note .text {
  /* color: grey; */
  font-size: 14px;
  text-align: left;
  width: 70%;
}

.notesTab .btns {
  margin-bottom: 1rem;
}

.notesTab .btns svg {
  padding: 3px;
  width: 30px;
  stroke: #4fc8e9;
  cursor: pointer;
}

.notesTab .notesTabHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.notesTab .notesTabHeader button {
  color: white;
  background-color: transparent;
  border: 1px solid;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
  /* font-weight: bold; */
  transition: all 0.2s ease-in-out;
}
.notesTab .notesTabHeader button:hover {
  border-color: #4fc8e9;
}

.notesTab .addNoteContainer {
  transition: all 2s ease-in-out;
}
.notesTab .addNoteContainer form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  transition: all 0.2s ease-in-out;
  width: 100%;
}
.notesTab .addNoteContainer input {
  background-color: transparent;
  border: 1px solid;
  border-radius: 5px;
  color: white;
  outline: none;
  padding: 5px 10px;
}

.notesTab .addNoteContainer textarea {
  background-color: transparent;
  border: 1px solid;
  border-radius: 5px;
  color: white;
  outline: none;
  width: 100%;
  padding: 5px 10px;
}

.notesTab .addNoteContainer .headline {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.notesTab .addNoteContainer .headline button {
  color: white;
  background-color: #4fc8e9;
  border: none;
  border-radius: 5px;
  padding: 3px 10px;
  font-size: 14px;
  transition: all 0.2s ease-in-out;
  border: 1px solid transparent;
}
.notesTab .addNoteContainer .headline button:hover {
  color: #4fc8e9;
  background-color: transparent;
  border-color: #4fc8e9;
}

.notesTab .hideAddNew {
  transform: translateY(-110%);
  display: none;
}

.notesTab .showAddNew {
  transform: translateY(0%);
  display: block;
}

.detailContent {
  display: flex;
  gap: 2rem;
  align-items: flex-start;
  justify-content: flex-start;
}

.detailContent .rightDetailContent {
  text-align: center;
}

.detailContent .leftDetailContent img {
  border-radius: 20px;
}

.user_detail_img {
  width: 300px;
}

.userDetail__pdf_privew {
  width: 400px;
  height: 300px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.userDetail__pdf_privew_bg {
  position: absolute!important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 300px;
  background-color: #0d6efd;
  opacity: 20%;
}

.userDetail__pdf_privew .react-pdf__Page__canvas {
  margin: 0 auto;
  width: 400px !important;
  height: 300px!important;
  border-radius: 20px;
}

/* react-pdf__Page__textContent textLayer */
.userDetail__pdf_privew .react-pdf__Page__textContent {
  /* max-width: should be parent compoennt */
  max-width: 400px;
  max-height: 300px;
}